import React, { useState, useContext } from "react";
import styled, { ThemeContext, css } from "styled-components";
import { FormattedInput } from "formatted-input";
import { fallbackValues } from "./FormLayouts.theme.js";
import { themeComponent } from "../../../util/themeUtils";
import { createIdFromString } from "../../../util/general.js";
import Text from "../text";
import { Box, Cluster, Stack } from "../layouts";
import { FONT_WEIGHT_REGULAR } from "../../../constants/style_constants";
import { ERROR_COLOR, ROYAL_BLUE } from "../../../constants/colors";

const InputField = styled.input`
  border: 1px solid
    ${({ field, showErrors, themeValues }) =>
      (field.dirty && field.hasErrors) || (field.hasErrors && showErrors)
        ? ERROR_COLOR
        : themeValues.borderColor};
  border-radius: 2px;
  height: ${({ $customHeight }) => ($customHeight ? $customHeight : "48px")};
  width: 100%;
  padding: 1rem;
  min-width: 100px;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  font-size: 1.1rem;
  font-family: Public Sans;
  line-height: 2rem;
  font-weight: ${FONT_WEIGHT_REGULAR};
  background-color: ${({ themeValues }) =>
    themeValues.inputBackgroundColor && themeValues.inputBackgroundColor};
  color: ${({ themeValues }) => themeValues.color && themeValues.color};
  box-shadow: none;
  ${({ background, themeValues }) =>
    background &&
    `background: ${themeValues.inputBackgroundColor} url(${background}) no-repeat right 0.5rem center;`}
  transition: background 0.3s ease;

  &:focus {
    outline: 3px solid ${ROYAL_BLUE};
    outline-offset: 2px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: #6e727e;
      background-color: #f7f7f7;
    `}

  ${({ $extraStyles }) =>
    css`
      ${$extraStyles}
    `}
`;

// eslint-disable-next-line no-unused-vars
const FormattedInputField = styled(({ showErrors, themeValues, ...props }) => (
  <FormattedInput {...props} />
))`
  border: 1px solid
    ${({ field, showErrors, themeValues }) =>
      (field.dirty && field.hasErrors) || (field.hasErrors && showErrors)
        ? ERROR_COLOR
        : themeValues.borderColor};
  border-radius: 2px;
  height: ${({ $customHeight }) => ($customHeight ? $customHeight : "48px")};
  width: 100%;
  padding: 1rem;
  min-width: 100px;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  font-size: 1.1rem;
  line-height: 2rem;
  font-weight: ${FONT_WEIGHT_REGULAR};
  background-color: ${({ themeValues }) =>
    themeValues.inputBackgroundColor && themeValues.inputBackgroundColor};
  color: ${({ themeValues }) => themeValues.color && themeValues.color};
  box-shadow: none;

  &:focus {
    outline: 3px solid ${ROYAL_BLUE};
    outline-offset: 2px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: #6e727e;
      background-color: #f7f7f7;
    `}

  ${({ extraStyles }) =>
    css`
      ${extraStyles}
    `}
`;

const FormInput = ({
  type = "text",
  labelTextWhenNoError = "",
  errorMessages,
  isNum = false,
  isEmail = false,
  helperModal = false,
  field,
  fieldActions,
  showErrors,
  formatter = null,
  decorator,
  themeValues,
  background,
  customHeight,
  autocompleteValue,
  extraStyles,
  removeFromValue, // regex of characters to remove before setting value
  dataQa = null,
  isRequired = false,
  errorFieldExtraStyles,
  showFieldErrorRow = true,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { isMobile } = useContext(ThemeContext);

  const setValue = value => {
    if (removeFromValue !== undefined) {
      return fieldActions.set(value.replace(removeFromValue, ""));
    }
    return fieldActions.set(value);
  };

  const handleOnBlur = value => {
    // Sets the empty value to trigger a validation error if it's required.
    if (isRequired && value === "") {
      setValue("");
    }
    setIsFocused(false);
  };

  return (
    <Stack childGap="0.25rem">
      <Box padding="0">
        {helperModal ? (
          <Cluster justify="space-between" align="center">
            <Text
              as="label"
              color={themeValues.labelColor}
              variant="pS"
              weight={themeValues.fontWeight}
              extraStyles={`word-break: break-word;
                  font-family: Public Sans;
                  &::first-letter {
                    text-transform: uppercase;
                  }`}
              id={createIdFromString(labelTextWhenNoError)}
            >
              {labelTextWhenNoError}
            </Text>
            {helperModal()}
          </Cluster>
        ) : (
          <Box padding="0" minWidth="100%">
            <Cluster justify="space-between" align="center">
              <Text
                as="label"
                color={themeValues.labelColor}
                variant="pS"
                fontWeight={themeValues.fontWeight}
                extraStyles={`word-break: break-word;
                    font-family: Public Sans;
                    &::first-letter {
                      text-transform: uppercase;
                    }`}
                id={createIdFromString(labelTextWhenNoError)}
              >
                {labelTextWhenNoError}
              </Text>
              {type === "password" && (
                <Text
                  variant="pS"
                  color={themeValues.linkColor}
                  weight={themeValues.fontWeight}
                  hoverStyles={themeValues.hoverFocusStyles}
                  extraStyles={`text-decoration: underline; cursor: pointer; &:focus { outline-offset: -2px; }`}
                  onClick={() => setShowPassword(!showPassword)}
                  tabIndex="0"
                  aria-label={showPassword ? "Hide Password" : "Show password"}
                  aria-live="polite"
                  onKeyPress={e =>
                    e.key === "Enter" && setShowPassword(!showPassword)
                  }
                >
                  {showPassword ? "Hide" : "Show"}
                </Text>
              )}
              {isMobile && decorator && (
                <Box padding="0 0 0 auto">{decorator}</Box>
              )}
            </Cluster>
          </Box>
        )}
      </Box>
      <Box padding="0">
        {formatter ? (
          <FormattedInputField
            aria-labelledby={createIdFromString(labelTextWhenNoError)}
            aria-describedby={createIdFromString(
              labelTextWhenNoError,
              "error message"
            )}
            aria-invalid={
              (field.dirty && field.hasErrors) ||
              (field.hasErrors && showErrors)
            }
            onChange={value => setValue(value)}
            onBlur={e => handleOnBlur(e.target.value)}
            type={type}
            value={field.rawValue}
            pattern={isNum ? "[0-9]*" : ""}
            inputMode={isNum ? "numeric" : isEmail ? "email" : "text"}
            field={field}
            formatter={formatter}
            showErrors={showErrors}
            themeValues={themeValues}
            $customHeight={customHeight}
            $extraStyles={extraStyles}
            data-qa={dataQa || labelTextWhenNoError}
            autoComplete={autocompleteValue}
            required={isRequired}
            // Additional handler to detect autofilled values
            {...(autocompleteValue && {
              onFocus: e => {
                if (!isFocused) {
                  setValue(e.target?.value);
                  setIsFocused(true);
                }
              }
            })}
            {...props}
          />
        ) : (
          <InputField
            aria-labelledby={createIdFromString(labelTextWhenNoError)}
            aria-describedby={createIdFromString(
              labelTextWhenNoError,
              "error message"
            )}
            aria-invalid={
              (field.dirty && field.hasErrors) ||
              (field.hasErrors && showErrors)
            }
            onChange={e => setValue(e.target.value)}
            onBlur={e => handleOnBlur(e.target.value)}
            type={type === "password" && showPassword ? "text" : type}
            value={field.rawValue}
            pattern={isNum ? "[0-9]*" : ""}
            inputMode={isNum ? "numeric" : isEmail ? "email" : "text"}
            field={field}
            showErrors={showErrors}
            themeValues={themeValues}
            background={background}
            $customHeight={customHeight}
            $extraStyles={extraStyles}
            data-qa={dataQa || labelTextWhenNoError}
            autoComplete={autocompleteValue}
            required={isRequired}
            {...props}
          />
        )}
      </Box>
      {showFieldErrorRow && (
        <Stack
          direction="row"
          justify="space-between"
          aria-live="polite"
          aria-atomic={true}
        >
          {(field.hasErrors && field.dirty) ||
          (field.hasErrors && showErrors) ? (
            <Text
              color={ERROR_COLOR}
              variant="pXS"
              weight={themeValues.fontWeight}
              extraStyles={`word-break: break-word;
              font-family: Public Sans;
              &::first-letter {
                text-transform: uppercase;
              }
              ${errorFieldExtraStyles};`}
              id={createIdFromString(labelTextWhenNoError, "error message")}
            >
              {errorMessages[field.errors[0]]}
            </Text>
          ) : (
            <Text
              extraStyles={`height: ${themeValues.lineHeight}; ${errorFieldExtraStyles};`}
            />
          )}
          {!isMobile && decorator && (
            <Box padding="0 0 0 auto">{decorator}</Box>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default themeComponent(
  FormInput,
  "FormInput",
  fallbackValues,
  "default"
);

import React, { useState, useEffect, useRef, forwardRef } from "react";
import { fallbackValues } from "./MultipleSelectFilter.theme";
import { themeComponent } from "../../../util/themeUtils";
import { Box, Center } from "../../atoms/layouts";
import { FormInput } from "../../atoms/form-layouts";
import ButtonWithAction from "../../atoms/button-with-action";
import Checkbox from "../../atoms/checkbox";
import { GHOST_GREY, WHITE } from "../../../constants/colors";
import { Text } from "../../atoms";
import DropdownIconV2 from "../../atoms/dropdown/DropdownIconV2";
import { FONT_WEIGHT_REGULAR } from "../../../constants/style_constants";
import { noop } from "../../../util/general";
import {
  FilterContainer,
  FilterButton,
  FilterDropdown
} from "./MultipleSelectFilter.styled";

const ScrollableOptionsList = ({
  id,
  optionsList,
  selectedOptions,
  themeValues,
  selectOption,
  maxSelections,
  name
}) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const checkboxRefs = useRef([]);
  const isMaxSelectionReached =
    maxSelections && maxSelections === selectedOptions?.length;
  const isChecked = option =>
    selectedOptions?.some(
      selectedOption => selectedOption?.name === option?.name
    );

  useEffect(() => {
    if (
      focusedIndex !== -1 &&
      checkboxRefs.current &&
      checkboxRefs.current[focusedIndex]
    ) {
      checkboxRefs.current[focusedIndex].focus(); // move focus to the active option
    }
  }, [focusedIndex]);

  const handleKeyDown = event => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setFocusedIndex(prevIndex =>
        prevIndex < optionsList.length - 1 ? prevIndex + 1 : 0
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setFocusedIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : optionsList.length - 1
      );
    } else if (event.key === " ") {
      event.preventDefault();
      // Select option on spacebar press if the maximum selection hasn't been reached.
      const validFocusedIndex = focusedIndex < 0 ? 0 : focusedIndex;
      if (
        !isMaxSelectionReached ||
        (focusedIndex !== -1 && isChecked(optionsList[validFocusedIndex]))
      ) {
        selectOption(optionsList[validFocusedIndex]);
      }
    } else if (event.key === "Tab") {
      // Reset focus when tabbing out of the list.
      setTimeout(() => {
        setFocusedIndex(-1);
      }, 500);
    }
  };

  return (
    <Box
      id={id}
      role="listbox"
      padding="0"
      extraStyles={`
        overflow-y: auto; 
        max-height: 250px; 
        display: flex; 
        flex-flow: column;
      `}
      onKeyDown={handleKeyDown}
    >
      {optionsList.map((option, index) => {
        const checked = isChecked(option);
        const isDisabled = isMaxSelectionReached && !checked;
        const tabIndex =
          index === focusedIndex || (index === 0 && focusedIndex === -1)
            ? "0"
            : "-1";
        return (
          <Box
            padding="0"
            key={index}
            extraStyles={`
              :hover,
              :active,
              :focus {
                background-color: ${themeValues.primaryColor};
              }
          `}
          >
            <Checkbox
              ref={el => (checkboxRefs.current[index] = el)}
              title={option.name}
              name={option.name}
              checked={checked}
              onChange={() => (isDisabled ? noop : selectOption(option))}
              textExtraStyles={`font-size: 0.875rem; margin: 0;`}
              disabled={isDisabled}
              extraStyles={`
                padding: 0.075rem 0.325rem; 
                margin: 0;
                :hover,
                :active,
                :focus {
                  background-color: ${themeValues.primaryColor};
                }
            `}
              checkboxMargin="0.3rem"
              role="option"
              checkboxExtraStyles={`
                width: 1.375rem; 
                height: 1.375rem; 
                ${
                  checked && !isDisabled
                    ? `background: ` + themeValues.secondaryColor + `;`
                    : ""
                }`}
              tabIndex={tabIndex}
              dataQa={`${name}-option-${index}`}
            />
          </Box>
        );
      })}
    </Box>
  );
};

const MultipleSelectFilter = ({
  actions,
  autocompleteValue,
  btnContentOverride,
  disabled,
  extraStyles,
  fields,
  filterLabel,
  hasIcon = false,
  icon: Icon,
  maxSelections,
  name = "MultipleSelectFilter",
  onApply = noop,
  onClear = noop,
  options,
  placeholder = "Search",
  searchable = true,
  selectedOptions,
  setSelectedOptions,
  themeValues,
  truncateBtnTextWidth = "15rem"
}) => {
  const [itemList, setItemList] = useState([]);
  const [opened, setOpened] = useState(false);
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);
  const filterButtonRef = useRef(null);
  const applyFilterButtonRef = useRef(null);
  const filterDropdownID = `${name}-filter-dropdown`;
  const checkboxListID = `${name}-checkbox-list`;

  const backgroundColor = opened
    ? themeValues.primaryColor
    : selectedOptions?.length
    ? themeValues.secondaryColor
    : WHITE;
  const contentColor = !opened && selectedOptions?.length ? WHITE : "#292A33";

  const completeOptionsList = itemList
    .slice()
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  const selectValues = items => items.map(item => item.value);
  const selectOption = selectedOption => {
    if (selectValues(selectedOptions).includes(selectedOption.value)) {
      const fewerOptions = selectedOptions.filter(
        option => option.value !== selectedOption.value
      );
      setSelectedOptions(fewerOptions);
    } else {
      const moreOptions = selectedOptions.concat(selectedOption);
      setSelectedOptions(moreOptions);
    }
  };

  useEffect(() => setItemList(options), [options]);

  useEffect(() => {
    const filteredItems = options.filter(item =>
      item?.name
        ?.toLowerCase()
        .includes(fields?.searchTerm?.rawValue?.toLowerCase())
    );
    // If no items are filtered, display the entire list of options.
    if (filteredItems?.length) {
      setItemList(filteredItems);
    } else {
      setItemList(options);
    }
  }, [fields.searchTerm.rawValue]);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
      /*
        Close the dropdown if we hit the Escape key, 
        or if we are tabbing forward away from the last button (apply button) 
        or tabbing backward past the filter button.
      */
      if (
        event.key === "Escape" ||
        (event.key === "Tab" &&
          !event.shiftKey &&
          applyFilterButtonRef.current &&
          applyFilterButtonRef.current.contains(event.target)) ||
        (event.key === "Tab" &&
          event.shiftKey &&
          filterButtonRef.current &&
          filterButtonRef.current.contains(event.target))
      ) {
        setOpened(false);
        actions.fields.searchTerm.set("");
        onApply(selectedOptions);
      }
    };
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setOpened(false);
        actions.fields.searchTerm.set("");
        onApply(selectedOptions);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.addEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <FilterContainer ref={containerRef} extraStyles={`${extraStyles}`}>
      <FilterButton
        ref={filterButtonRef}
        variant="tertiary"
        action={() => {
          actions.fields.searchTerm.set("");
          setOpened(!opened);
        }}
        aria-haspopup="listbox"
        aria-expanded={opened}
        aria-controls={filterDropdownID}
        backgroundColor={backgroundColor}
        dataQa={`${name}-filter-button`}
        contentOverride
      >
        {btnContentOverride ? (
          btnContentOverride
        ) : (
          <Center
            as="span"
            style={{ display: "flex", flexDirection: "row" }}
            intrinsic
          >
            {hasIcon && <Icon color={contentColor} />}
            <Center
              as="span"
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 0.5rem 0 0.25rem"
              }}
              intrinsic
            >
              <Text
                variant="pS"
                color={contentColor}
                extraStyles={`
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  ${truncateBtnTextWidth && `max-width:` + truncateBtnTextWidth}
                `}
              >
                {selectedOptions?.length
                  ? `${filterLabel ? filterLabel + ": " : ""}${
                      selectedOptions[0].name
                    }`
                  : `${filterLabel ? filterLabel : ""}`}
              </Text>
              <Text color={contentColor} variant="pS">
                {selectedOptions?.length && selectedOptions?.length > 1
                  ? `, +${selectedOptions?.length - 1} More`
                  : ""}
              </Text>
            </Center>
            <DropdownIconV2 color={contentColor} />
          </Center>
        )}
      </FilterButton>
      <FilterDropdown
        id={filterDropdownID}
        ref={dropdownRef}
        hidden={!opened}
        role="combobox"
        aria-expanded={opened}
        aria-haspopup="listbox"
        aria-owns={checkboxListID}
      >
        <Box padding="0 0 0.5rem">
          {searchable && options?.length > 8 && (
            <FormInput
              autocompleteValue={autocompleteValue}
              showFieldErrorRow={false}
              errorMessages={{}}
              field={fields.searchTerm}
              fieldActions={actions.fields.searchTerm}
              placeholder={placeholder}
              disabled={disabled}
              extraStyles={`
                height: 2.875rem;
                border: 0; 
                border-radius: 0;
                padding: 0.45rem;
                font-size: 0.875rem;
                border-bottom: 1px solid ${GHOST_GREY};
             `}
            />
          )}
        </Box>
        <ScrollableOptionsList
          id={checkboxListID}
          optionsList={completeOptionsList}
          selectedOptions={selectedOptions}
          themeValues={themeValues}
          selectOption={selectOption}
          maxSelections={maxSelections}
          name={name}
        ></ScrollableOptionsList>
        <Box
          padding="0 0.5rem 0.0625rem 0.5rem"
          extraStyles={`
            max-height: 100px; 
            display: flex; 
            flex-flow: row; 
            justify-content: space-between;
            border-top: 1px solid ${GHOST_GREY};
          `}
        >
          <ButtonWithAction
            action={() => {
              setOpened(false);
              setSelectedOptions([]);
              actions.fields.searchTerm.set("");
              onClear();
            }}
            variant="tertiary"
            extraStyles={`
              padding: 0.2rem; 
              margin: 0.5rem; 
              min-height: auto; 
              min-width: auto;
            `}
            textExtraStyles={`font-weight: ${FONT_WEIGHT_REGULAR};`}
            text="Clear"
            dataQa={`${name}-clear-filters`}
          ></ButtonWithAction>
          <ButtonWithAction
            ref={applyFilterButtonRef}
            action={() => {
              setOpened(false);
              actions.fields.searchTerm.set("");
              onApply(selectedOptions);
            }}
            variant="tertiary"
            extraStyles={`
              padding: 0.2rem; 
              margin: 0.5rem; 
              min-height: auto; 
              min-width: auto;
            `}
            textExtraStyles={`font-weight: ${FONT_WEIGHT_REGULAR};`}
            text="Apply"
            dataQa={`${name}-apply-filters`}
          ></ButtonWithAction>
        </Box>
      </FilterDropdown>
    </FilterContainer>
  );
};

export default themeComponent(
  MultipleSelectFilter,
  "MultipleSelectFilter",
  fallbackValues
);
